<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-1 text-primary">
            <mat-icon class="me-2 align-middle">extension</mat-icon> {{ campaign.nombre }}
        </h4>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <mat-stepper orientation="vertical" #stepper [linear]="false">
                            <ng-template matStepperIcon="edit">
                                <mat-icon>done</mat-icon>
                            </ng-template>
                            <mat-step errorMessage="Fase pendiente, revise la información y autorizaciones">
                                <ng-template matStepLabel>
                                    <span class="m-0 h5">
                                        Fase de Diagramación <mat-icon class="align-middle">analytics</mat-icon>
                                    </span>
                                    <!-- <span class="ms-4 badge bg-info">Archivo cargado</span> -->
                                    <span class="ms-4 badge" [ngClass]="calculateAprovals(1,2).class">Aprobaciones {{ calculateAprovals(1,2).aprovals }} de 2</span>
                                </ng-template>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <h6 *ngIf="hasFilesOnPhase(1)">
                                            <mat-icon class="align-middle">update</mat-icon>
                                            Última actualización: <strong>{{ lastModPhase(1).created_at | date : "dd/MM/yyyy" : "-0000" }} - {{ lastModPhase(1).nombre_usuario }} </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="float-end align-middle">
                                            <a *ngIf="hasFilesOnPhase(1)" href="https://drive.google.com/file/d/{{ lastModPhase(1).archivo }}" target="_blank" mat-stroked-button color="primary">Descarga último archivo <mat-icon class="align-middle ms-1">file_download</mat-icon></a>
                                            <button *ngIf="hasPermissions('comern',true,true)" mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile(1)">Cargar nuevo archivo
                                            <!-- <button mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile()">Cargar nuevo archivo -->
                                                <mat-icon>upload</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 my-4">                                        
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(1,1)).class">
                                                <div class="step-counter">
                                                    <button *ngIf="hasFilesOnPhase(1)" [disabled]="!hasPermissions('trade',true,true)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuDiagMark" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(1,1)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(1)  " mat-icon-button class="mb-1">
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(1,1)).icon}}</mat-icon>
                                                    </button>
                                                  
                                                    <mat-menu #menuDiagMark="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(1,1,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(1,1,2)">
                                                            <mat-icon class="text-warning">pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(1,1)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Trade</div>
                                                <div *ngIf="getPhaseStatus(1,1) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(1,1) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(1,1)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(1,1) |  date : "dd/MM/yyyy": "-0000"}} </div>
                                            </div>
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(1,2)).class">
                                                <div class="step-counter">
                                                    <button *ngIf="hasFilesOnPhase(1)" [disabled]="!hasPermissions('promo',true,true)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuDiagPromo" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(1,2)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(1)" mat-icon-button class="mb-1" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(1,2)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuDiagPromo="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(1,2,1)">
                                                            <mat-icon class="text-success" >check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(1,2,2)">
                                                            <mat-icon class="text-warning" >pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(1,2)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Promociones</div>
                                                <div *ngIf="getPhaseStatus(1,2) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(1,2) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(1,2)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(1,2) |  date : "dd/MM/yyyy": "-0000" }} </div>
                                            </div>
                                            <!-- <div class="stepper-item" [ngClass]="'active'">
                                                <div class="step-counter"><mat-icon>cancel</mat-icon></div>
                                                <div class="step-name">Aprobación (VoBo)</div>
                                            </div> -->
                                        </div>   
                                        <ng-container *ngIf="hasFilesOnPhase(1)">
                                            <div *ngIf="getPhaseStatus(1,1)==3" class="alert alert-danger mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> ¡El área de Trade no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                            </div>
                                            <div *ngIf="getPhaseStatus(1,2)==3" class="alert alert-danger mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> ¡El área de Promociones no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                            </div>
    
                                            <div *ngIf="getPhaseStatus(1,1)==2" class="alert alert-warning mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> Área de Trade pendiente de retroalimentación de archivo.
                                            </div>   
                                            <div *ngIf="getPhaseStatus(1,2)==2" class="alert alert-warning mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> Área de Promociones pendiente de retroalimentación de archivo.
                                            </div> 
                                        </ng-container> 

                                    </div>
                                    <div class="col-md-12">
                                        <span class="align-middle">Histórico de Archivos de Diagramación</span>
                                        <div class="table-responsive text-nowrap">
                                            <table class="table table-hover overflow-auto">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Archivo</th>
                                                        <th>Chat</th>
                                                        <th>Cargado por</th>
                                                        <th>Fecha de Registro</th>
                                                        <th>Equipo</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    <tr *ngFor="let p of getArchivosByPhase(1)">
                                                        <td>
                                                            {{ p.id }}
                                                        </td>
                                                        <td>
                                                            <a href="https://drive.google.com/file/d/{{p.archivo}}">
                                                               {{ p.nombre }}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs(p.id, p.nombre)">
                                                                <mat-icon *ngIf="p.msg_inc == 0" >chat</mat-icon>
                                                                <mat-icon *ngIf="p.msg_inc > 0" [matBadge]="p.msg_inc">chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {{ p.nombre_usuario }}
                                                        </td>
                                                        <td>
                                                            {{ p.created_at | date : "dd/MM/yyyy HH:mma": "-0000"}}
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-warning">{{ getRol(p.id_perfil) }}</span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="getArchivosByPhase(1).length == 0">
                                                        <td colspan="6">
                                                            <i>No se ha cargado ningún archivo</i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <!--<tbody class="table-border-bottom-0">
                                                    <tr>
                                                        <td>
                                                            4
                                                        </td>
                                                        <td>
                                                            <a href="">
                                                                diagramacionV-Final.zip
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs()">
                                                                <mat-icon [matBadge]="2">chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            Rodrigo Segovia
                                                        </td>
                                                        <td>
                                                            28/04/2023
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-warning">BÚHO</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            3
                                                        </td>
                                                        <td>
                                                            <a href="">
                                                                diagramacionV3.zip
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs()">
                                                                <mat-icon>chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            Juan López
                                                        </td>
                                                        <td>
                                                            27/04/2023
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-info">FDA</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            2
                                                        </td>
                                                        <td>
                                                            <a href="">
                                                                diagramacionV2.zip
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs()">
                                                                <mat-icon>chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            Rodrgio Segovia
                                                        </td>
                                                        <td>
                                                            27/04/2023
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-warning">Búho</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            <a href="">
                                                                diagramacionV1.zip
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs()">
                                                                <mat-icon>chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            Juan López
                                                        </td>
                                                        <td>
                                                            27/04/2023
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-info">FDA</span>
                                                        </td>
                                                    </tr>                                                
                                                </tbody>-->
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row mt-2">
                                    <div class="col-sm-6 align-middle">
                                        <button mat-flat-button type="button" color="primary">Descargar diagramación<mat-icon>download</mat-icon></button>
                                    </div>
                                    <div class="col-sm-6 text-end mb-2">
                                        <button mat-stroked-button type="button" color="primary">Cargar diagramación<mat-icon>upload</mat-icon></button>
                                    </div>
                                </div> -->
                            </mat-step>
                            <mat-step errorMessage="Fase pendiente, revise la información y autorizaciones">
                                <ng-template matStepLabel>
                                    <span class="m-0 h5">
                                        Fase de Artes <mat-icon class="align-middle">brush</mat-icon>
                                    </span>
                                    <!-- <span class="ms-4 badge bg-warning">Archivo cargado</span> -->
                                    <span class="ms-4 badge" [ngClass]="calculateAprovals(2,2).class">Aprobaciones {{ calculateAprovals(2,2).aprovals }} de 2</span>
                                </ng-template>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <h6 *ngIf="hasFilesOnPhase(2)">
                                            <mat-icon class="align-middle">update</mat-icon>
                                            Última actualización: <strong>{{ lastModPhase(2).created_at | date : "dd/MM/yyyy" }} - {{ lastModPhase(2).nombre_usuario }} </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="float-end align-middle">
                                            <a *ngIf="hasFilesOnPhase(2)" href="https://drive.google.com/file/d/{{ lastModPhase(2).archivo }}" target="_blank" mat-stroked-button color="primary">Descarga último archivo <mat-icon class="align-middle ms-1">file_download</mat-icon></a>
                                            <button *ngIf="hasPermissions('desin',true,true)" mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile(2)">Cargar nuevo archivo
                                                <mat-icon>upload_file</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 my-4">                                        
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(2,4)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('legal',true,true)" *ngIf="hasFilesOnPhase(2)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuArt1Mark" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(2,4)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(2)" mat-icon-button class="mb-1" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(2,4)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuArt1Mark="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(2,4,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(2,4,2)">
                                                            <mat-icon class="text-warning">pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(2,4)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Legal</div>
                                                <div *ngIf="getPhaseStatus(2,4) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(2,4) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(2,4)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(2,4) |  date : "dd/MM/yyyy": "-0000" }} </div>
                                            </div>
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(2,2)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('promo',true,true)" *ngIf="hasFilesOnPhase(2)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuArt1Promo" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(2,2)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(2)" mat-icon-button class="mb-1">
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(2,2)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuArt1Promo="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(2,2,1)">
                                                            <mat-icon class="text-success" >check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(2,2,2)">
                                                            <mat-icon class="text-warning" >pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(2,2)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Promociones</div>
                                                <div *ngIf="getPhaseStatus(2,2) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(2,2) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(2,2)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(2,2) |  date : "dd/MM/yyyy ": "-0000" }} </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="hasFilesOnPhase(2)">
                                            <div *ngIf="getPhaseStatus(2,4)==3" class="alert alert-danger mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> ¡El área de Legal no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                            </div>
                                            <div *ngIf="getPhaseStatus(2,2)==3" class="alert alert-danger mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> ¡El área de Promociones no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                            </div>
    
                                            <div *ngIf="getPhaseStatus(2,4)==2" class="alert alert-warning mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> Área de Legal pendiente de retroalimentación de archivo.
                                            </div>   
                                            <div *ngIf="getPhaseStatus(2,2)==2" class="alert alert-warning mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> Área de Promociones pendiente de retroalimentación de archivo.
                                            </div> 
                                        </ng-container> 
                                        <!--<div class="alert alert-danger mt-3" role="alert">
                                            <mat-icon class="align-middle">report</mat-icon> ¡El área de Promociones no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                        </div>-->
                                    </div>
                                    <div class="col-md-12">
                                        <span class="align-middle">Histórico de Archivos de Artes</span>
                                        <div class="table-responsive text-nowrap">
                                            <table class="table table-hover overflow-auto">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Archivo</th>
                                                        <th>Chat</th>
                                                        <th>Cargado por</th>
                                                        <th>Fecha de Registro</th>
                                                        <th>Equipo</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    <tr *ngFor="let p of getArchivosByPhase(2)">
                                                        <td>
                                                            {{ p.id }}
                                                        </td>
                                                        <td>
                                                            <a href="https://drive.google.com/file/d/{{p.archivo}}">
                                                               {{ p.nombre }}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs(p.id, p.nombre)">
                                                                <mat-icon *ngIf="p.msg_inc == 0" >chat</mat-icon>
                                                                <mat-icon *ngIf="p.msg_inc > 0" [matBadge]="p.msg_inc">chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {{ p.nombre_usuario }}
                                                        </td>
                                                        <td>
                                                            {{ p.created_at | date : "dd/MM/yyyy HH:mma": "-0000"}}
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-warning">BÚHO</span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="getArchivosByPhase(2).length == 0">
                                                        <td colspan="6">
                                                            <i>No se ha cargado ningún archivo</i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step errorMessage="Fase pendiente, revise la información y autorizaciones">
                                <ng-template matStepLabel>
                                    <span class="m-0 h5">
                                        Fase 2 de Artes <mat-icon class="align-middle">brush</mat-icon>
                                    </span>
                                    <!-- <span class="ms-4 badge bg-warning">Archivo cargado</span> -->
                                    <span class="ms-4 badge" [ngClass]="calculateAprovals(3,2).class">Aprobaciones {{ calculateAprovals(3,2).aprovals }} de 2</span>
                                </ng-template>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <h6 *ngIf="hasFilesOnPhase(3)">
                                            <mat-icon class="align-middle">update</mat-icon>
                                            Última actualización: <strong>{{ lastModPhase(3).created_at | date : "dd/MM/yyyy" }} - {{ lastModPhase(3).nombre_usuario }} </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="float-end align-middle">
                                            <a *ngIf="hasFilesOnPhase(3)" href="https://drive.google.com/file/d/{{ lastModPhase(3).archivo }}" target="_blank" mat-stroked-button color="primary">Descarga último archivo <mat-icon class="align-middle ms-1">file_download</mat-icon></a>
                                            <button *ngIf="hasPermissions('desin',true,true)" mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile(3)">Cargar nuevo archivo
                                                <mat-icon>upload_file</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 my-4">                                        
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(3,2)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('promo',true,true)" *ngIf="hasFilesOnPhase(3)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuArt2Promo" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(3,2)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(3)" mat-icon-button class="mb-1" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(3,2)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuArt2Promo="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(3,2,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item>
                                                            <mat-icon class="text-warning" (click)="changePhaseSts(3,2,2)">pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(3,2)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Promociones</div>
                                                <div *ngIf="getPhaseStatus(3,2) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(3,2) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(3,2)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(3,2) |  date : "dd/MM/yyyy": "-0000" }} </div>
                                            </div>
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(3,3)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('comer',true,true)" *ngIf="hasFilesOnPhase(3)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuArt2Comer" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(3,3)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(3)" mat-icon-button class="mb-1">
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(3,3)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuArt2Comer="matMenu" >
                                                        <button mat-menu-item (click)="changePhaseSts(3,3,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(3,3,2)">
                                                            <mat-icon class="text-warning" >pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(3,3)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Comercial</div>
                                                <div *ngIf="getPhaseStatus(3,3) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(3,3) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(3,3)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(3,3) |  date : "dd/MM/yyyy": "-0000" }} </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="hasFilesOnPhase(3)">
                                            <div *ngIf="getPhaseStatus(3,2)==3" class="alert alert-danger mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> ¡El área de Promociones no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                            </div>
                                            <div *ngIf="getPhaseStatus(3,3)==3" class="alert alert-danger mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> ¡El área de Comercial no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                            </div>
    
                                            <div *ngIf="getPhaseStatus(3,2)==2" class="alert alert-warning mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> Área de Promociones pendiente de retroalimentación de archivo.
                                            </div>   
                                            <div *ngIf="getPhaseStatus(3,3)==2" class="alert alert-warning mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> Área de Comercial pendiente de retroalimentación de archivo.
                                            </div> 
                                        </ng-container> 
                                        <!--<div class="alert alert-warning mt-3" role="alert">
                                            <mat-icon class="align-middle">report</mat-icon> Área Comercial pendiente de retroalimentación de archivo.
                                        </div>-->
                                    </div>
                                    <div class="col-md-12">
                                        <span class="align-middle">Histórico de Archivos de Artes</span>
                                        <div class="table-responsive text-nowrap">
                                            <table class="table table-hover overflow-auto">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Archivo</th>
                                                        <th>Chat</th>
                                                        <th>Cargado por</th>
                                                        <th>Fecha de Registro</th>
                                                        <th>Equipo</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    <tr *ngFor="let p of getArchivosByPhase(3)">
                                                        <td>
                                                            {{ p.id }}
                                                        </td>
                                                        <td>
                                                            <a href="https://drive.google.com/file/d/{{p.archivo}}">
                                                               {{ p.nombre }}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs(p.id, p.nombre)">
                                                                <mat-icon *ngIf="p.msg_inc == 0" >chat</mat-icon>
                                                                <mat-icon *ngIf="p.msg_inc > 0" [matBadge]="p.msg_inc">chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {{ p.nombre_usuario }}
                                                        </td>
                                                        <td>
                                                            {{ p.created_at | date : "dd/MM/yyyy HH:mma": "-0000"}}
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-warning">BÚHO</span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="getArchivosByPhase(3).length == 0">
                                                        <td colspan="6">
                                                            <i>No se ha cargado ningún archivo</i>
                                                        </td>
                                                    </tr>                                               
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step errorMessage="Fase pendiente, revise la información y autorizaciones">
                                <ng-template matStepLabel>
                                    <span class="m-0 h5">
                                        Fase 3 de Artes <mat-icon class="align-middle">brush</mat-icon>
                                    </span>
                                    <!-- <span class="ms-4 badge bg-warning">Archivo cargado</span> -->
                                    <span class="ms-4 badge" [ngClass]="calculateAprovals(4,3).class">Aprobaciones {{ calculateAprovals(4,3).aprovals }} de 3</span>
                                </ng-template>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <h6 *ngIf="hasFilesOnPhase(4)">
                                            <mat-icon class="align-middle">update</mat-icon>
                                            Última actualización: <strong>{{ lastModPhase(4).created_at | date : "dd/MM/yyyy" }} - {{ lastModPhase(4).nombre_usuario }} </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="float-end align-middle">
                                            <a *ngIf="hasFilesOnPhase(4)" href="https://drive.google.com/file/d/{{ lastModPhase(4).archivo }}" target="_blank" mat-stroked-button color="primary">Descarga último archivo <mat-icon class="align-middle ms-1">file_download</mat-icon></a>
                                            <button *ngIf="hasPermissions('desin',true,true)" mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile(4)">Cargar nuevo archivo
                                                <mat-icon>upload_file</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 my-4">                                        
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(4,2)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('promo',true,true)" *ngIf="hasFilesOnPhase(4)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuArt3Promo" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(4,2)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(4)" mat-icon-button class="mb-1">
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(4,2)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuArt3Promo="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(4,2,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(4,2,1)">
                                                            <mat-icon class="text-warning">pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(4,2)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Promociones</div>
                                                <div *ngIf="getPhaseStatus(4,2) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(4,2) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(4,2)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(4,2) |  date : "dd/MM/yyyy ": "-0000" }} </div>
                                            </div>
                                            <!--<div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(4,4)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('legal',true,true)" *ngIf="hasFilesOnPhase(4)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuArt3Legal" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(4,4)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(4)" mat-icon-button class="mb-1">
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(4,4)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuArt3Legal="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(4,4,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(4,4,2)">
                                                            <mat-icon class="text-warning">pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(4,4)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Legal</div>
                                                <div *ngIf="getPhaseStatus(4,4) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(4,4) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(4,4)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(4,4) |  date : "dd/MM/yyyy": "-0000" }} </div>
                                            </div>-->
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(4,5)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('comern',true,true)" *ngIf="hasFilesOnPhase(4)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuArt3Comercializacion" >
                                                        <mat-icon>{{ getStatusClassIcon(getPhaseStatus(4,5)).icon }}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(4)" mat-icon-button class="mb-1" >
                                                        <mat-icon>{{ getStatusClassIcon(getPhaseStatus(4,5)).icon }}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuArt3Comercializacion="matMenu">
                                                        <button mat-menu-item (click)="changePhaseSts(4,5,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(4,5,1)">
                                                            <mat-icon class="text-warning">pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(4,5)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Comercialización</div>
                                                <div *ngIf="getPhaseStatus(4,5) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(4,5) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(4,5)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(4,5) |  date : "dd/MM/yyyy": "-0000" }} </div>
                                            </div>
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(4,6)).class">
                                                <div class="step-counter">
                                                    <button mat-icon-button class="mb-1" (click)="openDialogProviders()">
                                                        <mat-icon>{{ getStatusClassIcon(getPhaseStatus(4,6)).icon }}</mat-icon>
                                                    </button>                                                    
                                                </div>
                                                <div class="step-name">Proveedores</div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="hasFilesOnPhase(4)">
                                        <div *ngIf="getPhaseStatus(4,2)==3" class="alert alert-danger mt-3" role="alert">
                                            <mat-icon class="align-middle">report</mat-icon> ¡El área de Promociones no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                        </div>
                                        <div *ngIf="getPhaseStatus(4,5)==3" class="alert alert-danger mt-3" role="alert">
                                            <mat-icon class="align-middle">report</mat-icon> ¡El área de Comercialización no ha autorizado el archivo! Revisa los comentarios y vuelve a cargar el archivo para su autorización.
                                        </div>

                                        <div *ngIf="getPhaseStatus(4,2)==2" class="alert alert-warning mt-3" role="alert">
                                            <mat-icon class="align-middle">report</mat-icon> Área de Promociones pendiente de retroalimentación de archivo.
                                        </div>   
                                        <div *ngIf="getPhaseStatus(4,5)==2" class="alert alert-warning mt-3" role="alert">
                                            <mat-icon class="align-middle">report</mat-icon> Área de Comercialización pendiente de retroalimentación de archivo.
                                        </div> 
                                    </ng-container> 

                                    <div class="col-md-12">
                                        <span class="align-middle">Histórico de Archivos de Artes</span>
                                        <div class="table-responsive text-nowrap">
                                            <table class="table table-hover overflow-auto">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Archivo</th>
                                                        <th>Chat</th>
                                                        <th>Cargado por</th>
                                                        <th>Fecha de Registro</th>
                                                        <th>Equipo</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    <tr *ngFor="let p of getArchivosByPhase(4)">
                                                        <td>
                                                            {{ p.id }}
                                                        </td>
                                                        <td>
                                                            <a href="https://drive.google.com/file/d/{{p.archivo}}">
                                                               {{ p.nombre }}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs(p.id, p.nombre)">
                                                                <mat-icon *ngIf="p.msg_inc == 0" >chat</mat-icon>
                                                                <mat-icon *ngIf="p.msg_inc > 0" [matBadge]="p.msg_inc">chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {{ p.nombre_usuario }}
                                                        </td>
                                                        <td>
                                                            {{ p.created_at | date : "dd/MM/yyyy HH:mma": "-0000"}}
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-warning">BÚHO</span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="getArchivosByPhase(4).length == 0">
                                                        <td colspan="6">
                                                            <i>No se ha cargado ningún archivo</i>
                                                        </td>
                                                    </tr>                                                    
                                                    <!-- <tr>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            <a href="">
                                                                artesV1.zip
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs()">
                                                                <mat-icon>chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            Juan López
                                                        </td>
                                                        <td>
                                                            27/04/2023
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-info">FDA</span>
                                                        </td>
                                                    </tr> -->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                            <!--New Phase-->
                            <mat-step errorMessage="Fase pendiente, revise la información y autorizaciones">
                                <ng-template matStepLabel>
                                    <span class="m-0 h5">
                                        Fase de Distribución <mat-icon class="align-middle">card_travel</mat-icon>
                                    </span>
                                    <!-- <span class="ms-4 badge bg-warning">Archivo cargado</span> -->
                                    <span class="ms-4 badge" [ngClass]="calculateAprovals(5,1).class">Aprobaciones {{ calculateAprovals(5,1).aprovals }} de 1</span>
                                </ng-template>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <h6 *ngIf="hasFilesOnPhase(5)">
                                            <mat-icon class="align-middle">update</mat-icon>
                                            Última actualización: <strong>{{ lastModPhase(5).created_at | date : "dd/MM/yyyy" }} - {{ lastModPhase(5).nombre_usuario }} </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="float-end align-middle">
                                            <a *ngIf="hasFilesOnPhase(5)" href="https://drive.google.com/file/d/{{ lastModPhase(5).archivo }}" target="_blank" mat-stroked-button color="primary">Descarga último archivo <mat-icon class="align-middle ms-1">file_download</mat-icon></a>
                                            <button *ngIf="hasPermissions('comern',true,true)" mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadCampaignFile(5)">Cargar nuevo archivo
                                                <mat-icon>upload_file</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 my-4">                                        
                                        <div class="stepper-wrapper">
                                            <div class="stepper-item" [ngClass]="getStatusClassIcon(getPhaseStatus(5,5)).class">
                                                <div class="step-counter">
                                                    <button [disabled]="!hasPermissions('comern',true,true)" *ngIf="hasFilesOnPhase(5)" mat-icon-button class="mb-1" [matMenuTriggerFor]="menuDistComer" >
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(5,5)).icon}}</mat-icon>
                                                    </button>
                                                    <button *ngIf="!hasFilesOnPhase(5)" mat-icon-button class="mb-1">
                                                        <mat-icon>{{getStatusClassIcon(getPhaseStatus(5,5)).icon}}</mat-icon>
                                                    </button>
                                                    <mat-menu #menuDistComer="matMenu" >
                                                        <button mat-menu-item (click)="changePhaseSts(5,5,1)">
                                                            <mat-icon class="text-success">check_circle</mat-icon>
                                                            <span>Autorizado</span>
                                                        </button>
                                                        <button mat-menu-item (click)="changePhaseSts(5,5,2)">
                                                            <mat-icon class="text-warning" >pending</mat-icon>
                                                            <span>Pendiente</span>
                                                        </button>
                                                        <button mat-menu-item (click)="openDialogCommentNoAuth(5,5)">
                                                            <mat-icon class="text-danger">cancel</mat-icon>
                                                            <span>No Autorizado</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                                <div class="step-name">Comercialización</div>
                                                <div *ngIf="getPhaseStatus(5,5) == 1" style="font-weight: normal;"> Fecha Aprobación : {{ getPhaseDate(5,5) |  date : "dd/MM/yyyy HH:mma": "-0000" }} </div>
                                                <div *ngIf="isNextPhaseRol(5,5)" style="font-weight: normal;"> Fecha Límite : {{ calculateNext(1,1) |  date : "dd/MM/yyyy ": "-0000" }} </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="hasFilesOnPhase(5)">    
                                            <div *ngIf="getPhaseStatus(5,5)==2" class="alert alert-warning mt-3" role="alert">
                                                <mat-icon class="align-middle">report</mat-icon> Área de Comercialización pendiente de retroalimentación de archivo.
                                            </div>   
                                        </ng-container> 
                                        <!--<div class="alert alert-warning mt-3" role="alert">
                                            <mat-icon class="align-middle">report</mat-icon> Área Comercial pendiente de retroalimentación de archivo.
                                        </div>-->
                                    </div>
                                    <div class="col-md-12">
                                        <span class="align-middle">Histórico de Archivos de Fase Distribución</span>
                                        <div class="table-responsive text-nowrap">
                                            <table class="table table-hover overflow-auto">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Archivo</th>
                                                        <th>Chat</th>
                                                        <th>Cargado por</th>
                                                        <th>Fecha de Registro</th>
                                                        <th>Equipo</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    <tr *ngFor="let p of getArchivosByPhase(5)">
                                                        <td>
                                                            {{ p.id }}
                                                        </td>
                                                        <td>
                                                            <a href="https://drive.google.com/file/d/{{p.archivo}}">
                                                               {{ p.nombre }}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <button mat-icon-button (click)="openDialogFileLogs(p.id, p.nombre)">
                                                                <mat-icon *ngIf="p.msg_inc == 0" >chat</mat-icon>
                                                                <mat-icon *ngIf="p.msg_inc > 0" [matBadge]="p.msg_inc">chat</mat-icon>
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {{ p.nombre_usuario }}
                                                        </td>
                                                        <td>
                                                            {{ p.created_at | date : "dd/MM/yyyy HH:mma": "-0000"}}
                                                        </td>
                                                        <td>
                                                            <span class="badge bg-warning">BÚHO</span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="getArchivosByPhase(3).length == 0">
                                                        <td colspan="6">
                                                            <i>No se ha cargado ningún archivo</i>
                                                        </td>
                                                    </tr>                                               
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                        </mat-stepper>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>

