// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://qa.api.nest.buhoms.com',
  role_admin_id: 1,
  role_buho: 2,
  role_client: 3,
  roles: [
    {
      "id": 2,
      "rol": "Buho"
    },
    {
      "id": 3,
      "rol": "Cliente"
    }
  ],
  status_surveys : [
    {
       id: 1,
       nombre : "Pendiente",
       class: "badge badge-warning"
    },
    {
      id: 2,
      nombre : "Perfecto",
      class: "badge badge-success"
    },
    {
      id: 3,
      nombre : "Alerta",
      class: "badge badge-error"
    },
    {
      id: 4,
      nombre : "Alerta Anulada",
      class: "badge badge-success"
    }
  ],
  status_seguimientos : [
    {
      id: 1,
      nombre : "Alertas pendientes",
      class: "badge badge-warning"
    },
    {
      id: 2,
      nombre : "Pendiente Atender",
      class: "badge badge-error"
    },
    {
      id: 3,
      nombre : "En revisión",
      class: "badge badge-inverse"
    },
    {
      id: 4,
      nombre : "Resuelto",
      class: "badge badge-info"
    },
    {
      id: 5,
      nombre : "Caja Perfecta",
      class: "badge badge-success"
    }
  ],
  status_survticket : [
    {
       id: 1,
       nombre : "Pendiente",
       class: "badge badge-warning"
    },
    {
      id: 2,
      nombre : "Completada",
      class: "badge badge-success"
    },
  ],
  status_ticket :[
   {
     id: 1,
     nombre : "Pendiente revisar",
     class: "badge badge-inverse"
   },
   {
    id: 2,
    nombre : "En revisión",
    class: "badge badge-sp1"
    },
    {
      id: 3,
      nombre : "Resuelto",
      class: "badge badge-success"
    },
    {
      id: 4,
      nombre : "Resuelto (Cancelación)",
      class: "badge badge-success"
    }
  ],
  tipos_ticket :[
    {
      id: 5,
      nombre : "Faltante",
      class: "badge badge-info"
    },
    {
      id: 1,
      nombre : "Material dañado",
      class: "badge badge-info"
   },
   {
     id: 2,
     nombre : "Kit incompleto",
     class: "badge badge-info"
   },
   {
     id: 3,
     nombre : "Solicitud adicional",
     class: "badge badge-info"
   },
   {
     id: 4,
     nombre : "Otro",
     class: "badge badge-info"
   },
   {
     id: 7,
     nombre : "Anulación",
     class: "badge badge-info"
   },
  ],
  att_types: [{ type_id : 1, name : "SI/NO"},
  { type_id : 2, name : "FECHA"}, 
  { type_id : 3, name : "OPCIONES"}, 
  { type_id : 6, name : "NÚMERO"}, 
  { type_id : 4, name : "RANGO"},
   { type_id : 5, name : "TEXTO"}]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
