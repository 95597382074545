export class getProjectModel {
    active!: number;
}

export class ProjectListModel {
    progress : Array<ProjectModel> = [];
    finished : Array<ProjectModel> = [];
    inactive : Array<ProjectModel> = [];
    pastyears: any = {};
}

export class ProjectModel {
    id!:number;
    name!:string;
    date!:string;
    folder!:string;
    locked!:number;
    notif!:number;
    active!:number;
    status!:number;
    owner!:string;
}

export class editProjectModel{
    id_proj!: number;
    name! : string;
    date!: string;
    locked! : number;
    active! : number;
    id_compania!: number;
}

export class newProjectModel {
    name!: string;
    date!: string;
    id_compania!: number;
}
export class ProjectWorldModel {
    id!:number;
    name!: string;
    id_proj!: number;
    active!: boolean;
    inProgress!: number;
    campaigns: Array<ProjectCampaignModel> = [];
}

export class ProjectCampaignModel {
    id!:number;
    name!:string;
    status!:number;
    date!:string;
    owner!:string;
}

export class ProjectWorldListModel {
    project!: ProjectModel;
    progress : Array<ProjectWorldModel> = [];
    finished : Array<ProjectWorldModel> = [];
}

export class ProjectCampListModel {
    project!: ProjectModel;
    progress : Array<ProjectCampaignModel> = [];
    finished : Array<ProjectCampaignModel> = [];
}