import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { TicketsGenerateExtraComponent } from '../../shared/dialogs/tickets-generate-extra/tickets-generate-extra.component';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  //Material related vars 
  displayedColumns: string[] = ['sucursal','zona', 'alertas', 'st_encuesta', 'st_seguimiento', 'Acciones'];
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  companias : any = [];
  usrcompany : number = 0;
  campaignsData : any = {};
  zones : any = [];
  expanded_filters : boolean = true;

  //---filter group ---
  selectedcompany : any = null;
  selectedzone : any = null;
  selectedCamp : any = 0;
  selectedStsSurv : any = null;
  selectedStsTrack : any = null;
  //---end---
  statusTracking : any = [];
  statusSurv : any = [];
  datatracking : any = [];
  hostName : any = "";
  isClawCamp : boolean = false;

  isback? : boolean = false;
  constructor(
    private matDialog: MatDialog,  
    private _params: ActivatedRoute,
    private _router : Router,
    private surveyS : SurveysService,
    private main : MainService,
    private _titleService: Title,
    private userS: UsersService,
    private campaignService: CampaignsService,) { 

      if(_router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString()!= undefined){
        this.isback = _router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().includes("/admin/ticket/");
      }
      console.log(this.isback);
    }
  

  ngOnInit(): void {
    if(this.userS.isBuhoUsr()){
      this.getCompanias();
    }else{
      this.usrcompany = this.userS.getUser().companies[0].id
      console.log()
      this.loadFilters();
      //this.loadCampaigns();
      //this.loadZones();
    }
    this.getStatusTracking();
    this.getStatusSurv();
    //this.loadTickets();
    this.hostName = window.location.protocol + '//' + window.location.host + "/surveys/";
  }

  getStatusCount(id_status : number){
    return this.datatracking? this.datatracking.filter((x:any)=> x.st_encuesta == id_status).length : 0;
  }

  getSeguimientosCount(id_seg : number){
    return this.datatracking? this.datatracking.filter((x:any)=> x.st_seguimiento == id_seg && x.st_encuesta != 1).length : 0;
  }

  fillFilters(){
    if(this.isback){
    this.selectedzone = this.surveyS.selectedzone;
    this.selectedCamp  = this.surveyS.selectedCamp;
    this.selectedStsSurv  = this.surveyS.selectedStsSurv;
    this.selectedStsTrack  = this.surveyS.selectedStsTrack;
    this.loadTracking();
    }else{
      this.resetFilters();
    }
  }

  updateOverallFilters(){
    this.surveyS.selectedzone = this.selectedzone;
    this.surveyS.selectedCamp = this.selectedCamp;
    this.surveyS.selectedStsSurv = this.selectedStsSurv;
    this.surveyS.selectedStsTrack =  this.selectedStsTrack;
  }

  resetFilters(){
    this.surveyS.selectedzone = null;
    this.surveyS.selectedCamp = 0;
    this.surveyS.selectedStsSurv = null;
    this.surveyS.selectedStsTrack = null;
  }

  loadTracking(){
    this.main.ShowLoading();
    let list_id_zones = this.zones.map((e:any)=>e.id);
    if(this.selectedzone) {
      list_id_zones = [this.selectedzone];
    }
    
    this.surveyS.getTracking(this.selectedcompany, this.isClaw(this.selectedCamp) ? this.selectedCamp.replace('-c','') : this.selectedCamp , this.isClaw(this.selectedCamp) ? list_id_zones : this.selectedzone, 
    this.selectedStsSurv, this.selectedStsTrack, this.isClawNest(this.selectedCamp)).subscribe((results:any)=>{
      this.main.HideLoading();
      if(results.message == "Success") {
        
        this.isClawCamp = this.isClaw(this.selectedCamp);
        this.searchedCamp = this.selectedCamp;
        this.datatracking = results.data;
        this.dataSource = new MatTableDataSource(this.datatracking);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.updateCampanaName();
        this.updateOverallFilters();

        this.expanded_filters = true;
        setTimeout(() => {
          this.expanded_filters = false;
        }, 150);
        
      }
    });
  } 

  campanaName = "";

  updateCampanaName(){
    if(this.isClawCamp){
      const sel_cn = this.campaignsData.claw.find((x:any)=>{ return x.id == parseInt(this.selectedCamp.replace('-c', ''))});
      this.campanaName = sel_cn?.name;
    }else{
      this.campanaName = this.campaignsData.nest.filter((x:any)=>{ return x.id ==  this.selectedCamp})[0].name;
    }
    console.log("selected campana name", this.selectedCamp, parseInt(this.selectedCamp.replace('-c', '')));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  getCompanias(){
    this.userS.getCompanies().subscribe((results: any)=>{
      if(results.message == "Success") {
        this.companias = [];
        
        if(results.data.length>1) {
          this.companias.push({ "id": null, "name": "-- Todas --" });
        }
        this.companias = this.companias.concat(results.data);

        if(results.data.length==1) {
          this.selectedcompany = this.companias[0].id;
        // this.loadCampaigns(); 
        // this.loadZones();
        this.loadFilters();
        }
      }
    },
    (err:any) => {
      console.log(err);
    })
  }

  isBuhoUser() : boolean {
    return this.userS.isBuhoUsr();
  }

  loadFilters(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getSurveyCampaigns(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          this.campaignsData = x.data;
          const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
          this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
          console.log(x);
         // if(this.campaignsData.nest.length===1) {
         //   this.selectedCamp = this.campaignsData.nest[0].id;
         // }
          //loadzones
          if(this.selectedcompany && this.selectedcompany != 0){
            this.main.ShowLoading();
            this.surveyS.getZones(this.selectedcompany).subscribe((x: any) => {
              this.main.HideLoading();
              if(x.message == "Success") {
                this.zones = x.data;
                this.fillFilters()
              }
            },
            (err:any) => {
                  this.main.HideLoading();
                }
            );
          }else{
            this.zones = []; 
          }
          //--------
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.campaignsData = {}; 
      }
    }else{
      this.main.ShowLoading();

      this.surveyS.getSurveyCampaigns(1).subscribe((x: any) => {
        this.main.HideLoading();
        this.campaignsData = x.data;
        const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
        this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
        console.log(x);
        if(this.campaignsData.nest.length===1) {
          this.selectedCamp = this.campaignsData.nest[0].id;
        }
          //loadzones
          this.main.ShowLoading();
          this.surveyS.getZonesByUser().subscribe((x: any) => {
            this.main.HideLoading();
            if(x.message == "Success") {
              this.zones = x.data;
              this.fillFilters()
            }
          },
          (err:any) => {
                this.main.HideLoading();
              }
          )
          //--------
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }
    //this.selectedCamp = 0
  }


  loadCampaigns(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getSurveyCampaigns(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          this.campaignsData = x.data;
          const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
          this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
          //if(this.campaignsData.nest.length===1) {
          //  this.selectedCamp = this.campaignsData.nest[0].id;
          //}
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.campaignsData = {}; 
      }
    }else{
      this.main.ShowLoading();
      this.surveyS.getSurveyCampaigns(1).subscribe((x: any) => {
        this.main.HideLoading();
        this.campaignsData = x.data;
        const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
        this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
       //if(this.campaignsData.nest.length===1) {
       //  this.selectedCamp = this.campaignsData.nest[0].id;
       //}
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }

    this.selectedCamp = 0
  }

  loadZones(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getZones(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          if(x.message == "Success") {
            this.zones = x.data;
          }
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.zones = []; 
      }
    }else{
      this.main.ShowLoading();
      this.surveyS.getZonesByUser().subscribe((x: any) => {
        this.main.HideLoading();
        if(x.message == "Success") {
          this.zones = x.data;
        }
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }
     this.selectedzone = null
  }

  getStatusTracking(){
    this.statusTracking = environment.status_seguimientos;
  }
  getStatusSurv(){
    this.statusSurv = environment.status_survticket;
  }

  getStatusSurvDat(idstatus : number){
    return environment.status_survticket.filter(x=> x.id  == idstatus)[0];
  }

  getStatusTrackingDat(idstatus : number){
    return environment.status_seguimientos.filter(x=> x.id  == idstatus)[0];
  }

  isClaw(id_camp : any) : boolean{
    let idcampEv = id_camp.toString();
    return idcampEv.includes("-c");
}

  isClawNest(id_camp : any) : string{
      let idcampEv = id_camp.toString();
      return idcampEv.includes("-c") ? "claw" : "nest";
  }

  searchedCamp : number = 0;

  search(){
   if(this.selectedCamp != 0){
   this.loadTracking();
   }
  }
  gotoSurvey(row : any){
    console.log(this.isClawCamp);
    this.surveyS.getEncuestaUrl(this.isClawCamp? { box_id : row.id, id_campana : row.id_campana_nest } 
      : { id_sucursal : row.id_sucursal, id_campana : this.searchedCamp }).subscribe((x:any)=>{
      if(x.message == "Success") {
        //console.log(x.data)
        var url = this.hostName + x.data.token;
        window.open(url, "_blank");
      }
    });

  }

  addExtraTicket(row:any){
    this.matDialog.open(TicketsGenerateExtraComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        row
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp.new_ticket){
          const url = '/admin/ticket/'+row.id_compania + '/' + row.id_campana  + '/' + row.id + '/claw'
          this._router.navigateByUrl(url);
        }
      }
    );
  }
}
