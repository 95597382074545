<div class="modal-header row justify-content-between">
    <div class="col-10">
        <h4 class="modal-title" id="mondalAddCampaignTitle">Generar ticket extraordiordinario</h4>
    </div>
    <div class="col-1 me-3">
        <button type="button" class="btn-close" mat-dialog-close></button>
    </div>
    <p> Sucursal: {{ distributionSelected.sucursal }}</p>
</div>

<mat-divider></mat-divider>

<mat-dialog-content class="pb-3">
    <div class="modal-body">

        <div *ngIf="isLoading" class="row w-100 justify-content-center">
            <mat-spinner diameter="50"></mat-spinner>
            <p class="text-center mt-3">Cargando materiales</p>
        </div>
    
        <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">

            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef> Producto </th>
              <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
            </ng-container>
          
            <ng-container matColumnDef="actions">
              <th class="text-center" mat-header-cell *matHeaderCellDef> Acciones </th>
              <td class="text-center" mat-cell *matCellDef="let row"> 
                <mat-checkbox [(ngModel)]="row.alerted" (change)="onSelectionChange(row)"></mat-checkbox>
                <button matTooltip="Asignar tipo" [matTooltipPosition]="'above'" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="row.alerted && !row.ticket_t">
                  <mat-icon>expand_more</mat-icon>
                </button>
            
                <mat-menu #menu="matMenu">
                  <button *ngFor="let stat of filterValidTickets()" (click)="setTicketType(row, stat)"  mat-menu-item >{{ stat.nombre }}</button>
                </mat-menu>
              </td>
            </ng-container>

            <ng-container matColumnDef="selector">
                <th class="text-center" mat-header-cell *matHeaderCellDef>Tipo de ticket</th>
                <td mat-cell class="text-center" *matCellDef="let row">
                    <span *ngIf="!row.ticket_t">-</span>
                    <div matTooltip="Cambiar tipo" [matTooltipPosition]="'above'">
                        <span class="pointer" [matMenuTriggerFor]="menu" *ngIf="row.ticket_t">{{ row.ticket_t?.nombre }}</span>
                        <button  mat-icon-button [matMenuTriggerFor]="menu" *ngIf="row.alerted && row.ticket_t">
                            <mat-icon>expand_more</mat-icon>
                        </button>
                    </div>
                    <mat-menu #menu="matMenu">
                    <button *ngFor="let stat of filterValidTickets()" (click)="setTicketType(row, stat)"  mat-menu-item >{{ stat.nombre }}</button>
                    </mat-menu>
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
          
    </div>
</mat-dialog-content>

<div class="modal-footer pt-3 d-flex justify-content-between">
    <div>
        <button [matMenuTriggerFor]="menu2" *ngIf="selected?.length> 1" [disabled]="isLoading" mat-flat-button class="badge-info text-white me-2">Asignar tipo <mat-icon>queue</mat-icon></button>
        <mat-menu #menu2="matMenu">
            <button *ngFor="let stat of filterValidTickets()" (click)="setTicketTypeForAll(stat)"  mat-menu-item >{{ stat.nombre }}</button>
        </mat-menu>
    </div>
    <div>
        <button class="me-3" mat-flat-button mat-dialog-close>Cancelar <mat-icon>close</mat-icon></button>
       
        <button (click)="onSubmit()" [disabled]="isLoading" mat-flat-button class="bg-success text-white">Enviar <mat-icon>send</mat-icon></button>
    </div>
</div>